<template>
  <div>
    <page-top :title="$t('invoice.AE64AD@menu')">
      <span class="fa fa-money fa-2x"></span>
    </page-top>
    <el-card class="box-card">
      <el-table border
                :data="invoiceList"
                :highlight-current-row="true"
                v-loading="loading"
                :element-loading-text="$t('public.4ABAB1@loading')"
                :header-cell-style="{'text-align':'center',}">
        <!--    發票日期    -->
        <el-table-column :label="$t('invoice.F9100D@invoice_date')" align="center" prop="invoice_date"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    發票號碼    -->
        <el-table-column :label="$t('invoice.0DE312@invoice_number')" align="center" prop="invoice_number"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    總銷售金額    -->
        <el-table-column :label="$t('invoice.A886987@gmv')" prop="gmv" min-width="1.5vw"
                         align="right" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    抽成率    -->
        <el-table-column :label="$t('invoice.EA5264@cost_ratio')" prop="cost_ratio" min-width="1vw"
                         align="center">
          <template slot-scope="scope">
            {{scope.row.cost_ratio}}%
          </template>
        </el-table-column>
        <!--    發票金額    -->
        <el-table-column :label="$t('invoice.9E1612@invoice_amount')" prop="amount" min-width="1vw"
                         align="right" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    付款狀態    -->
        <el-table-column :label="$t('invoice.84D521@pay_status')" prop="pay_status" min-width="1.5vw"
                         align="center" :sort-orders="['ascending', 'descending']" sortable>
          <template slot-scope="score">
            <span v-if="score.row.payment_status" style="color: darkgreen;font-weight: bold">
              {{ $t('invoice.A8312E@paid') }}
            </span>
            <span v-else style="color: red;font-weight: bold">
              {{ $t('invoice.F5D21D@unpaid') }}
            </span>
          </template>
        </el-table-column>
        <!--    顯示詳情    -->
        <el-table-column prop="btn" min-width="1.5vw" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goPrintDetail(scope.row)">
              {{ $t('invoice.ED8512@click_to_see_invoice') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="flex-end mt20">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[10,25,50,100]"
            :page-size="size"
            @current-change="handleCurrentChange"
            :current-page="page"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";

export default {
  name: "",
  components: {
    pageTop
  },
  data() {
    return {
      //company id
      cid: "",
      //Invoice List
      invoiceList: [],
      //total
      total: 0,
      //size
      size: 10,
      //current page
      page: 1,
      //loading
      loading: true,
    }
  },
  methods: {
    //轉跳發票明細
    goPrintDetail(data) {
      const {href} = this.$router.resolve({
        name: "printInvoice",
        query: {
          cid: this.cid,
          date: data.invoice_date,
          invoice_number: data.invoice_number,
        },
      });
      window.open(href, "_blank");
    },
    getInvoiceList() {
      this.$http.getInvoiceList({
        size: this.size,
        page: this.page
      }).then(res => {
        this.invoiceList = res.data.list
        this.cid = res.data.cid
        this.total = res.data.total
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.size = val;
      this.getInvoiceList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getInvoiceList();
    }
  },
  created() {
    this.getInvoiceList()
  }
}
</script>

<style lang="scss" scoped>

</style>
